//import { useState } from 'react'
//import emailjs from 'emailjs-com'
/*
const initialState = {
  name: '',
  email: '',
  message: '',
}*/
export const Contact = (props) => {
//  const [{ name, email, message }, setState] = useState(initialState)
/*
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }*/
//  const clearState = () => setState({ ...initialState })
/*
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }*/
  return (
    <div>
      <div id='contact'>
        <div className='container'>
        <div className='hrline'></div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='section-title'>
                <h2>Kontaktujte nás</h2>
                <p>
                  Pro více informací o našich službách nás neváhejte kontaktovat.
                </p>
                <br></br>
                {' '}
                <p>
                  GPS: {props.data ? props.data.gps : 'loading'}
                </p>
                <p>
                  <iframe title="mapy.cz" src="https://frame.mapy.cz/s/nesocatupo" width="500" height="333" frameborder="0"></iframe>
                </p>
                <p>
                  <img src="img/provozovna.jpg" width="500" height="333" className="img-responsive" alt="" />{" "}
                </p>
              </div>

            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Kontaktní informace</h3>
              <p>
                <span>
                  <i className='fa fa-pencil'></i> Jednatel
                </span>
                {props.data ? props.data.jednatel : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Adresa
                </span>{' '}
                {props.data ? props.data.address : 'loading'}
              </p>
              <p>
                IČO: {props.data ? props.data.ico : 'loading'}<br></br>
                DIČ: {props.data ? props.data.dic : 'loading'}
              </p>
              <br></br>
              <p>
                GPS: {props.data ? props.data.gps : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Telefon
                </span>{' '}
                Mobil: {' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
              <p>Pevná linka: {' '}
                {props.data ? props.data.pevnalinka : 'loading'}</p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='hrline'></div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 Design by{' '}
            <a href='http://singulit.cz' rel='nofollow'>
              SinguliT.cz
            </a>
          </p>
        </div>
      </div>
    </div>
  )


}
