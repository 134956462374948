export const Sluzby = (props) => {
  return (
    <div id="sluzby">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/stroje/DSC_2238.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sluzby-text">
              <h2>Soustružení</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Možnosti obrábění:</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.soustruzeni.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='hrline'></div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/stroje/DSC_2311.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sluzby-text">
              <h2>Frézování</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Možnosti obrábění:</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.frezovani.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div className='hrline'></div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/stroje/DSC_2285.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sluzby-text">
              <h2>Obrážení</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Možnosti obrábění:</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.obrazeni.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hrline'></div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/stroje/DSC_2261.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sluzby-text">
              <h2>Broušení</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Možnosti obrábění:</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.brouseni.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hrline'></div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/stroje/P1140089.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sluzby-text">
              <h2>Tepelné zpracování</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Pro vlastní výrobu zajišťujeme:</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.tepelzpracovani.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hrline'></div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/stroje/PC130007.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sluzby-text">
              <h2>Tvarové Elektroerozivní řezání drátem</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Možnosti obrábění:</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.elektroeroze.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hrline'></div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/stroje/DSC_2269.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="sluzby-text">
              <h2>Vyjiskřování</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Možnosti obrábění:</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.vyjiskrovani.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='hrline'></div>


      </div>
    </div>
  );
};
